export default function SmoothScroll() {
    const savedHash = window.location.hash;
    let isScrolling = false;

    if (savedHash) {
        history.replaceState(null, null, ' ');
    }

    function scrollToElement(selector) {
        if (isScrolling) {
            $('html, body').stop();
        }
        isScrolling = true; 

        const requiresAccordion = $(selector).find('.js-accordion-content').length > 0;

        if (requiresAccordion) {
            $('.js-accordion-content').each(function() {
                if ($(this).is(':visible')) {
                    $(this).slideUp(300).parent().removeClass('-open');
                }
            });
        }

        if ($(selector).length) {
            $('html, body').animate({
                scrollTop: $(selector).offset().top - 120
            }, 500, function() {
                isScrolling = false;
                if (requiresAccordion) {
                    const accordionContent = $(selector).find('.js-accordion-content');
                    if (!accordionContent.is(':visible')) {
                        accordionContent.slideDown(300).parent().addClass('-open');
                    }
                }
            });
        } else {
            isScrolling = false;
        }
    }

    $(document).on('click', 'a[href]', function(event) {
        var href = $(this).attr('href');
        var linkUrl;
        try {
            linkUrl = new URL(href, window.location.href);
        } catch (e) {
            linkUrl = new URL(window.location.href);
            linkUrl.hash = href;
        }

        if( document.body.classList.contains('search'))
        {
            return;
        }
      
        if ((linkUrl.hostname === window.location.hostname && linkUrl.pathname === window.location.pathname) || href.startsWith('#')) {
            event.preventDefault();
            var hash = href.startsWith('#') ? href : linkUrl.hash;
            scrollToElement(hash);
        }
    });

    $(window).on('load', function() {
        if (savedHash) {
            scrollToElement(savedHash);
        }
    });
}
